import { useContext, useEffect, useState } from "react";
import TitleCommon from "../../components/Common/Title";
import { ContextProviderWrapper } from "../../components/Context";
import { BlockFrame, ButtonCommon } from "../../Layout/styled";
import {
  NetworkContainer,
  NetworkListHeader,
  NetworkRef,
  NetworkStaking,
  NetworkTotalStaking,
  NetworkWrapper,
  StakingList,
  StakingListBody,
  StakingListHeader,
  StakingSum,
  TeamData,
  TeamDataHeader,
  TeamDataInfo,
} from "./styled";
import TableCommon from "../../components/Common/Table";
import NetworkItem from "./NetworkItem";
import NetworkMember from "./NetworkMember";
import { gql, useLazyQuery } from "@apollo/client";
import { useAccount } from "wagmi";
import { shortenAddress } from "../../utils/addressUser";
import toast from "react-hot-toast";
import CopyToClipboard from "react-copy-to-clipboard";
import LoadingCommon from "../../components/Loading";
import { convertFixed } from "../../utils/convertNumber";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import NetworkMemberDesktop from "./NetworkMemberDesktop";
import { useSelector } from "react-redux";

const Network = () => {
  const { open } = useWeb3Modal();
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  const { address } = useAccount();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userProfile }: any = useSelector((state: any) => state.user);

  const headingData = [
    {
      title: "Network Details",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => (
        <NetworkItem
          team={record.team}
          totalMember={record.totalMember}
          totalStaking={record.totalStaking}
          handleClickTeam={handleClickTeam}
          setIsModalOpen={setIsModalOpen}
          record={record}
        />
      ),
    },
  ];
  const headingDataDesktop = [
    {
      title: "Network Details",
      dataIndex: "name",
      key: "name",
      render: (text: any, data: any) => <NetworkMemberDesktop data={data} />,
    },
  ];

  //Api network
  const GET_NETWORK = gql`
    query network($address: String!) {
      network(address: $address) {
        totalStaking
        totalMember
        teams {
          totalStaking
          totalMember
          team
          memberF1 {
            address
            refCode
            totalStaking
            totalMember
            totalIncome
            level
            createdAt
          }
        }
      }
    }
  `;

  // get data network
  const [getNetwork, { data: dataNetwork, loading }] = useLazyQuery(
    GET_NETWORK,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (address) {
      getNetwork({ variables: { address } });
    }
  }, [address]);

  useEffect(() => {
    if (!loading) {
      setFilteredTeam(dataNetwork?.network?.teams[0]);
    }
  }, [loading]);

  // item filer
  const [filteredTeam, setFilteredTeam] = useState<any>({});
  const handleClickTeam = async (teamId: any) => {
    const filterData = dataNetwork?.network?.teams?.find(
      (item: any) => item.team === teamId
    );
    setFilteredTeam(filterData);
  };

  // modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {isDesktop ? (
        <>
          <NetworkContainer>
            <NetworkWrapper>
              <BlockFrame className="frame-list">
                <NetworkListHeader>
                  <TitleCommon title="My Network" />
                  <NetworkRef>
                    {userProfile?.wallet?.totalStaking > 100 && (
                      <>
                        {address ? (
                          <CopyToClipboard
                            text={`https://app.oniprotocol.io?${address}`}
                            onCopy={() =>
                              toast.success("Copied", { id: "copyRef" })
                            }
                          >
                            <ButtonCommon className="green">
                              <p>
                                <img
                                  width={24}
                                  height={24}
                                  src="./img/Common/diagonal.svg"
                                  alt="icon"
                                  loading="lazy"
                                />
                              </p>
                              {`https://app.oniprotocol.io?${shortenAddress(
                                address
                              )} `}
                            </ButtonCommon>
                          </CopyToClipboard>
                        ) : (
                          <ButtonCommon
                            className="green"
                            onClick={() => open()}
                          >
                            <p>
                              <img
                                width={24}
                                height={24}
                                src="./img/Common/diagonal.svg"
                                alt="icon"
                                loading="lazy"
                              />
                            </p>
                            Connect Wallet
                          </ButtonCommon>
                        )}
                      </>
                    )}
                    {/* <p>Or</p> */}
                    {/* {address ? (
                      <CopyToClipboard
                        text={`${address}`}
                        onCopy={() =>
                          toast.success("Copied", { id: "copyAddress" })
                        }
                      >
                        <ButtonCommon className="yellow">
                          <p>
                            <img
                              width={16}
                              height={16}
                              src="./img/Common/special_char.svg"
                              alt="icon"
                              loading="lazy"
                            />
                          </p>
                          {shortenAddress(address)}
                        </ButtonCommon>
                      </CopyToClipboard>
                    ) : (
                      <ButtonCommon className="yellow" onClick={() => open()}>
                        <p>
                          <img
                            width={16}
                            height={16}
                            src="./img/Common/special_char.svg"
                            alt="icon"
                            loading="lazy"
                          />
                        </p>
                        Connect Wallet
                      </ButtonCommon>
                    )} */}
                  </NetworkRef>
                </NetworkListHeader>
                <TeamData>
                  {address || dataNetwork ? (
                    <>
                      {dataNetwork?.network?.teams?.map(
                        (item: any, index: any) => {
                          return (
                            <li
                              className={
                                item.team === filteredTeam?.team ? "active" : ""
                              }
                              key={index}
                              onClick={() => {
                                handleClickTeam(item.team);
                              }}
                            >
                              <TeamDataHeader>
                                <div>
                                  <figure>
                                    <img
                                      width={36}
                                      height={41}
                                      src="./img/Dashboard/Network/team_icon.png"
                                      alt="icon"
                                      loading="lazy"
                                    />
                                  </figure>
                                  <p>
                                    {item.team < 10
                                      ? "0" + item.team
                                      : item.team}
                                  </p>
                                </div>
                                <p>
                                  Team{" "}
                                  {item.team < 10 ? "0" + item.team : item.team}
                                </p>
                              </TeamDataHeader>
                              <TeamDataInfo>
                                <div>
                                  <p>Member</p>
                                  <span>{item.totalMember}</span>
                                </div>
                                <div>
                                  <p>Reward / Reward</p>
                                  <span>
                                    {item.team === 1
                                      ? "8%"
                                      : item.team === 2
                                      ? "5%"
                                      : item.team === 3
                                      ? "2.5%"
                                      : "0.5%"}
                                  </span>
                                </div>
                                <div>
                                  <p>Total Staking</p>
                                  <span>{item.totalStaking}</span>
                                </div>
                              </TeamDataInfo>
                            </li>
                          );
                        }
                      )}
                    </>
                  ) : (
                    <>
                      {networkData.map((item: any, index: any) => {
                        return (
                          <li
                            className={
                              item.team === filteredTeam?.team ? "active" : ""
                            }
                            key={index}
                            onClick={() => {
                              handleClickTeam(item.team);
                            }}
                          >
                            <TeamDataHeader>
                              <div>
                                <figure>
                                  <img
                                    width={36}
                                    height={41}
                                    src="./img/Dashboard/Network/team_icon.png"
                                    alt="icon"
                                    loading="lazy"
                                  />
                                </figure>
                                <p>
                                  {item.team < 10 ? "0" + item.team : item.team}
                                </p>
                              </div>
                              <p>
                                Team{" "}
                                {item.team < 10 ? "0" + item.team : item.team}
                              </p>
                            </TeamDataHeader>
                            <TeamDataInfo>
                              <div>
                                <p>Member</p>
                                <span>{item.member}</span>
                              </div>
                              <div>
                                <p>Reward / Reward</p>
                                <span>
                                  {address
                                    ? item.team === 1
                                      ? "8%"
                                      : item.team === 2
                                      ? "5%"
                                      : item.team === 3
                                      ? "2.5%"
                                      : "0.5%"
                                    : "--"}
                                </span>
                              </div>
                              <div>
                                <p>Total Staking</p>
                                <span>{item.totalStaking}</span>
                              </div>
                            </TeamDataInfo>
                          </li>
                        );
                      })}
                    </>
                  )}
                </TeamData>
              </BlockFrame>
              {loading ? (
                <LoadingCommon className="isLoading" />
              ) : (
                <>
                  <NetworkStaking>
                    <StakingSum>
                      <div>
                        <div>
                          <figure>
                            <img
                              width={25}
                              height={24}
                              src="./img/Common/ppl_icon.png"
                              alt="icon"
                              loading="lazy"
                            />
                          </figure>
                          <h2>Total Team Staking</h2>
                        </div>
                        <p>
                          $
                          {dataNetwork?.network
                            ? convertFixed(dataNetwork?.network?.totalStaking)
                            : 0}
                        </p>
                      </div>
                    </StakingSum>
                    <StakingList>
                      <StakingListHeader>
                        <div>
                          <h2>Team {filteredTeam?.team || "--"}</h2>
                          <p>
                            Period: <span>24 Months</span>
                          </p>
                        </div>
                        <div>
                          <p>{filteredTeam?.totalMember || "0"}</p>
                          <figure>
                            <img
                              width={25}
                              height={24}
                              src="./img/Common/ppl_icon.png"
                              alt="icon"
                              loading="lazy"
                            />
                          </figure>
                        </div>
                      </StakingListHeader>
                      {filteredTeam?.memberF1?.length === 0 ? (
                        <p
                          style={{
                            textAlign: "center",
                          }}
                        >
                          Doesn't have any data
                        </p>
                      ) : (
                        <>
                          <StakingListBody>
                            <TableCommon
                              data={filteredTeam?.memberF1}
                              heading={headingDataDesktop}
                            />
                          </StakingListBody>
                        </>
                      )}
                    </StakingList>
                  </NetworkStaking>
                </>
              )}
            </NetworkWrapper>
          </NetworkContainer>
        </>
      ) : (
        <>
          <NetworkContainer>
            {/* <DashboardNoti /> */}
            <NetworkWrapper>
              <BlockFrame className="frame-list">
                <NetworkListHeader>
                  <NetworkRef>
                    {userProfile?.wallet?.totalStaking > 100 && (
                      <>
                        <CopyToClipboard
                          text={`https://app.oniprotocol.io?${address}`}
                          onCopy={() =>
                            toast.success("Copied", { id: "copyRef" })
                          }
                        >
                          <ButtonCommon className="green">
                            <p>
                              <img
                                width={24}
                                height={24}
                                src="./img/Common/diagonal.svg"
                                alt="icon"
                                loading="lazy"
                              />
                            </p>
                            {address
                              ? `https://app.oniprotocol.io?${shortenAddress(
                                  address
                                )} `
                              : "Connect Wallet"}
                          </ButtonCommon>
                        </CopyToClipboard>
                      </>
                    )}
                  </NetworkRef>
                  <NetworkTotalStaking>
                    <p>
                      <span>Total Team Staking</span>
                    </p>
                    <span className="total-staking">
                      $
                      {dataNetwork?.network
                        ? convertFixed(dataNetwork?.network?.totalStaking)
                        : 0}
                    </span>
                  </NetworkTotalStaking>
                </NetworkListHeader>
              </BlockFrame>
              <TeamData>
                <TableCommon
                  data={dataNetwork?.network?.teams}
                  heading={headingData}
                  pageSize={20}
                />
              </TeamData>
            </NetworkWrapper>
          </NetworkContainer>
          <NetworkMember
            filteredTeam={filteredTeam}
            headingDataDesktop={headingDataDesktop}
            isModalOpen={isModalOpen}
            handleCancel={handleCancel}
          />
        </>
      )}
    </>
  );
};

const networkData = [
  {
    team: 1,
    member: "--",
    reward: "--",
    totalStaking: "--",
  },
  {
    team: 2,
    member: "--",
    reward: "--",
    totalStaking: "--",
  },
  {
    team: 3,
    member: "--",
    reward: "--",
    totalStaking: "--",
  },
  {
    team: 4,
    member: "--",
    reward: "--",
    totalStaking: "--",
  },
  {
    team: 5,
    member: "--",
    reward: "--",
    totalStaking: "--",
  },
  {
    team: 6,
    member: "--",
    reward: "--",
    totalStaking: "--",
  },
  {
    team: 7,
    member: "--",
    reward: "--",
    totalStaking: "--",
  },
  {
    team: 8,
    member: "--",
    reward: "--",
    totalStaking: "--",
  },
  {
    team: 9,
    member: "--",
    reward: "--",
    totalStaking: "--",
  },
  {
    team: 10,
    member: "--",
    reward: "--",
    totalStaking: "--",
  },
  {
    team: 11,
    member: "--",
    reward: "--",
    totalStaking: "--",
  },
  {
    team: 12,
    member: "--",
    reward: "--",
    totalStaking: "--",
  },
];

export default Network;
