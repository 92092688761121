import React, { useContext, useEffect, useState } from "react";
import {
  BlockHistory,
  PoolContainer,
  PoolDetail,
  PoolImage,
  PoolLeft,
  PoolList,
  PoolReward,
  PoolRight,
  PoolRightStake,
  PoolWrapper,
  TitleMobile,
} from "./styled";
import {
  WithdrawProject,
  WithdrawText,
  WithdrawTitle,
} from "../Withdraw/styled";
import { ContextProviderWrapper } from "../../components/Context";
import TableCommon from "../../components/Common/Table";
import PoolPlan from "./PoolPlan";
import BackCommon from "../../components/Common/Back";
import ModalStake from "./ModalStake";
import { useSelector } from "react-redux";
import { useDispatch } from "../../redux/store";
import { fetchSetting } from "../../redux/slices/user";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useAccount, useSignMessage } from "wagmi";
import toast from "react-hot-toast";
import { Spin } from "antd";
import { PriceUsd } from "../Dashboard/styled";
import { shortenAddress } from "../../utils/addressUser";
import moment from "moment";
import web3 from "web3";
import { abiToken } from "../../contract/Pair";
import { addressContractToken } from "../../contract/Address";
import { convertFromWei } from "../../utils/convertNumber";
import { formatNumberMega } from "../../utils/formatNumber";
import ModalStakeAirdrop from "./PoolModal/Airdrop";
declare const window: Window & typeof globalThis & { ethereum: any };

const HARVEST = gql`
  mutation harvest($address: String!, $signature: String!, $message: String!) {
    harvest(address: $address, signature: $signature, message: $message)
  }
`;

const GET_WALLET = gql`
  query wallet($address: String!) {
    wallet(address: $address) {
      id
      address
      oni {
        balance
      }
      usdt {
        balance
      }
      feeWithdrawONI
      feeWithdrawUSDT
      totalJoinPoolShare
      totalLeavePoolShare
      autoJoinPoolShare
      totalRewardPoolShare
      totalReward
      totalRewardStaking
      totalRewardOnReward
      totalWithdraw
      totalStaking
      totalNetworkStaking
      totalLevelCommission
      autoJoinPoolShareTimeLimit
      oniPoolShare {
        balance
      }
      totalDirectCommission
      refCode
      maxout
      level
      investments {
        id
        address
        amount
        createdAt
        hash
      }
      transactions {
        id
        transactionType
        walletType
        amount
        fee
        note
        createdAt
        hash
        status
      }
      airdrop {
        balance
      }

      poolShares {
        id
        address
        amount
        amountAvlbLeave
        amountLeave
        timeLimit
        type
        manualStatus
        createdAt
        expiredAt
      }
    }
  }
`;

const Pool = () => {
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  const [detailPage, setDetailPage] = useState(false);
  const [isModalOpenStake, setIsModalOpenStake] = useState(false);
  const [isModalOpenStakeAirdrop, setIsModalOpenStakeAirdrop] = useState(false);
  const [harvest] = useMutation(HARVEST);
  const { userProfile, setting }: any = useSelector((state: any) => state.user);
  const { address } = useAccount();
  const { data: signature, signMessage, error }: any = useSignMessage();
  const [loadingWithdraw, setLoadingWithdraw] = useState(false);
  const dispatch = useDispatch();
  const verifyMessage = "Verify";
  const [loadingData, setLoadingData] = useState(false);
  const usdPrice = useSelector((state: any) => state.user.usdtPrice);
  const [dataHarvest, setDataHarvest] = useState<any>();

  //get wallet
  const [getWallet] = useLazyQuery(GET_WALLET, {
    fetchPolicy: "network-only",
  });

  //modal
  const handleCancelStake = () => {
    setIsModalOpenStake(false);
  };
  const handleCancelStakeAirdrop = () => {
    setIsModalOpenStakeAirdrop(false);
  };

  //data table
  const headingData = [
    {
      name: "Id",
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return <p className="table-text">{shortenAddress(data.id)}</p>;
      },
    },
    {
      name: "Date",
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <p className="table-text">
            {moment(Number(data.createdAt)).format("DD/MM/YYYY")}
          </p>
        );
      },
    },
    {
      name: "Amount",
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <p className="table-text amount">
            {formatNumberMega(data.amount)}
            <img
              src="/img/Dashboard/Pool/icn_token_small.png"
              width={32}
              height={32}
              loading="eager"
              alt="Oni"
            />
          </p>
        );
      },
    },
  ];

  //get pool
  useEffect(() => {
    dispatch(fetchSetting("totalPoolShare"));
  }, []);

  //api harvest
  const handleVerifyWallet = async () => {
    await signMessage({ message: verifyMessage });
  };

  const handleGetSignature = async () => {
    setLoadingWithdraw(true);
    console.log("here");
    handleVerifyWallet();
  };

  const handleWithdraw = async () => {
    try {
      const res = await harvest({
        variables: {
          address,
          signature,
          message: "Verify",
        },
      });
      if (res?.data?.withdraw) {
        toast.success("Success");
        await getWallet({ variables: { address } });
        setLoadingWithdraw(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("Fail");
      setLoadingWithdraw(false);
    }
  };

  useEffect(() => {
    if (signature) {
      handleWithdraw();
    }
  }, [signature]);

  useEffect(() => {
    if (error) {
      toast.error("Fail");
      setLoadingWithdraw(false);
    }
  }, [error]);

  //get data harvest
  useEffect(() => {
    if (userProfile?.wallet?.transactions) {
      const filteredHarvest = userProfile?.wallet?.transactions?.filter(
        (transaction: any) => transaction?.walletType === "oniPoolShare"
      );
      const reversedHarvest = [...filteredHarvest]?.reverse();
      setDataHarvest(reversedHarvest);
    }
  }, [userProfile]);

  // get oni
  const [oniBalance, setOniBalance] = useState<any>("");
  const getONIBalance = async () => {
    try {
      const Web3Balance = new web3("https://bsc-dataseed.binance.org/");
      const tokenContract = new Web3Balance.eth.Contract(
        abiToken,
        addressContractToken
      );
      const balance: any = await tokenContract.methods
        .balanceOf("0x8DbaFbfC01D9fF992673C0C07c80969518a126f2")
        .call();
      const formattedBalance: any = convertFromWei(balance, 18);
      setOniBalance(formattedBalance);
    } catch (error) {
      console.error("Error fetching ONI token balance:", error);
    }
  };
  useEffect(() => {
    getONIBalance();
  }, []);

  return (
    <>
      <ModalStake
        isModalOpenStake={isModalOpenStake}
        handleCancelStake={handleCancelStake}
        setLoadingData={setLoadingData}
      />
      <ModalStakeAirdrop
        isModalOpenStake={isModalOpenStakeAirdrop}
        handleCancelStake={handleCancelStakeAirdrop}
        setLoadingData={setLoadingData}
      />
      <PoolContainer className={detailPage ? "detail-page" : ""}>
        <PoolWrapper>
          <PoolLeft>
            {!detailPage ? (
              <>
                <WithdrawProject>
                  <p>ONIProtocol</p>
                  <span>Project</span>
                </WithdrawProject>
                <WithdrawTitle>SHARE POOL</WithdrawTitle>
                {/* <WithdrawText>
                  Get answers to commonly asked questions.
                </WithdrawText> */}
                <PoolImage>
                  <img
                    src={
                      isDesktop
                        ? "/img/Dashboard/Pool/pool_img_01.png"
                        : "/img/Dashboard/Pool/pool_img_02.png"
                    }
                    width={392}
                    loading="eager"
                    alt="SHARE POOL"
                  />
                </PoolImage>
                {isDesktop && (
                  <>
                    <PoolList>
                      <div className="content-left">
                        <div className="item-list">
                          <h3 className="item-title">Shared Pool</h3>
                          <p className="item-number">
                            {formatNumberMega(setting?.config)}
                            <span>/50,000,000</span>
                          </p>
                          {userProfile?.wallet && (
                            <PriceUsd style={{ marginBottom: "10px" }}>
                              ${formatNumberMega(usdPrice * setting?.config)}
                            </PriceUsd>
                          )}
                          <button className="button-contract">
                            Contract Pool
                            <img
                              src="img/Dashboard/mining/icn_external.png"
                              width={14}
                              height={14}
                              alt="external"
                            />
                          </button>
                          <img
                            className="image-oni"
                            src="/img/Dashboard/Pool/icn_01.png"
                            width={104}
                            alt=""
                          />
                        </div>
                      </div>
                      <PoolDetail>
                        <h3 className="title-detail">Pool Profit Detail</h3>
                        <ul className="list-pool-detail">
                          {listDetail.map((item: any, index: number) => (
                            <li key={index}>
                              <p>{item.textLeft}</p>
                              <p>{item.textRight}</p>
                            </li>
                          ))}
                        </ul>
                      </PoolDetail>
                    </PoolList>
                    <PoolReward>
                      <img
                        src="/img/Dashboard/Pool/icn_reward.png"
                        alt="Total Reward"
                        width={58}
                        height={58}
                        className="image-left"
                      />
                      <div className="content-right">
                        <h3 className="title-reward">Total Reward</h3>
                        <p className="number-reward">
                          {formatNumberMega(oniBalance ? oniBalance : "--")}{" "}
                          <img
                            src="/img/Dashboard/Pool/icn_token.png"
                            width={50}
                            height={50}
                            alt="Oni"
                          />
                        </p>
                        {userProfile?.wallet && (
                          <PriceUsd style={{ marginBottom: "10px" }}>
                            ${formatNumberMega(usdPrice * oniBalance)}
                          </PriceUsd>
                        )}
                        <p className="text-reward">
                          Profit Pool Distribute at 29th, Sep, 2024
                        </p>
                      </div>
                    </PoolReward>
                  </>
                )}
              </>
            ) : (
              <PoolPlan
                setDetailPage={setDetailPage}
                userProfile={userProfile}
                getWallet={getWallet}
                loadingData={loadingData}
                setLoadingData={setLoadingData}
              />
            )}
          </PoolLeft>
          <PoolRight>
            {!isDesktop && (
              <BackCommon backFunction={() => setDetailPage(false)} />
            )}
            <PoolRightStake>
              <h2 className="title-stake">Your Pool</h2>
              {/* <p className="text-stake">
                Each wallet can stake up to 100,000 ONI
              </p> */}
              <div className="block-stake">
                <span className="number-stake">
                  {userProfile?.wallet
                    ? formatNumberMega(userProfile?.wallet?.totalJoinPoolShare)
                    : "--"}{" "}
                  <img
                    src="/img/Dashboard/Pool/icn_token_small.png"
                    width={32}
                    height={32}
                    loading="eager"
                    alt="Oni"
                  />
                </span>
                {userProfile?.wallet && (
                  <PriceUsd
                    style={{ textAlign: "center", marginBottom: "10px" }}
                  >
                    $
                    {formatNumberMega(
                      usdPrice * userProfile?.wallet?.totalJoinPoolShare
                    )}
                  </PriceUsd>
                )}
                <ul className="list-button">
                  <li>
                    <button
                      className="button-plan"
                      onClick={() => setDetailPage(true)}
                    >
                      Your Joined Plan
                    </button>
                  </li>
                  <li>
                    <button
                      className="button-more"
                      onClick={() => setIsModalOpenStake(true)}
                      disabled={address ? false : true}
                    >
                      Join More
                    </button>
                  </li>
                </ul>
              </div>
              <div className="stake-reward">
                <div className="stake-reward-inner">
                  <h3 className="title-reward">Your Reward</h3>
                  <span className="number-reward">
                    {userProfile?.wallet
                      ? formatNumberMega(
                          userProfile?.wallet?.oniPoolShare?.balance
                        )
                      : "--"}{" "}
                    <img
                      src="/img/Dashboard/Pool/icn_token_small.png"
                      width={32}
                      height={32}
                      loading="eager"
                      alt="Oni"
                    />
                  </span>
                  {userProfile?.wallet && (
                    <PriceUsd
                      style={{ textAlign: "center", marginBottom: "5px" }}
                    >
                      $
                      {formatNumberMega(
                        usdPrice * userProfile?.wallet?.oniPoolShare?.balance
                      )}
                    </PriceUsd>
                  )}
                  <button
                    className="button-reward"
                    onClick={handleGetSignature}
                    disabled={
                      userProfile?.wallet &&
                      userProfile?.wallet?.oniPoolShare?.balance > 0
                        ? false
                        : true
                    }
                  >
                    {loadingWithdraw ? <Spin /> : "Harvest"}
                  </button>
                </div>
                {isDesktop && (
                  <div className="stake-reward-inner">
                    <h3 className="title-reward">Your Airdrop</h3>
                    <span className="number-reward">
                      {userProfile?.wallet
                        ? formatNumberMega(userProfile?.wallet?.airdrop?.balance)
                        : "--"}
                      <img
                        src="/img/Dashboard/Pool/icn_token_small.png"
                        width={32}
                        height={32}
                        loading="eager"
                        alt="Oni"
                      />
                    </span>
                    {userProfile?.wallet && (
                      <PriceUsd
                        style={{ textAlign: "center", marginBottom: "5px" }}
                      >
                        $
                        {formatNumberMega(
                          usdPrice * userProfile?.wallet?.airdrop?.balance
                        )}
                      </PriceUsd>
                    )}
                    <button
                      className="button-reward"
                      onClick={() => {
                        setIsModalOpenStakeAirdrop(true)
                      }}
                      disabled={
                        userProfile?.wallet &&
                        userProfile?.wallet?.oniPoolShare?.balance > 0
                          ? false
                          : true
                      }
                    >
                      {/* {loadingWithdraw ? <Spin /> : "Join Pool"} */}
                      Join Pool
                    </button>
                  </div>
                )}
              </div>
              {!isDesktop && (
                <div className="stake-reward" style={{ marginTop: "10px" }}>
                  <h3 className="title-reward">Your airdrop</h3>
                  <span className="number-reward">
                  {userProfile?.wallet
                        ? formatNumberMega(userProfile?.wallet?.airdrop?.balance)
                        : "--"}
                    <img
                      src="/img/Dashboard/Pool/icn_token_small.png"
                      width={32}
                      height={32}
                      loading="eager"
                      alt="Oni"
                    />
                  </span>
                  {userProfile?.wallet && (
                    <PriceUsd
                      style={{ textAlign: "center", marginBottom: "5px" }}
                    >
                      $ {formatNumberMega(
                          usdPrice * userProfile?.wallet?.airdrop?.balance
                        )}
                    </PriceUsd>
                  )}
                  <button
                    className="button-reward"
                    // onClick={handleGetSignature}
                    disabled={
                      userProfile?.wallet &&
                      userProfile?.wallet?.oniPoolShare?.balance > 0
                        ? false
                        : true
                    }
                  >
                    {/* {loadingWithdraw ? <Spin /> : "Join Pool"} */}
                    Join Pool
                  </button>
                </div>
              )}
            </PoolRightStake>
            <BlockHistory>
              <h3 className="title-history">Harvest History</h3>
              <p className="text-history">
                My top pool of potential candidates for the position
              </p>
              <TableCommon
                data={dataHarvest}
                heading={headingData}
                pageSize={5}
              />
            </BlockHistory>
          </PoolRight>
          {/* layout mobile */}
          {!isDesktop && (
            <>
              <TitleMobile>Pool Details</TitleMobile>
              <PoolList>
                <div className="content-left">
                  <div className="item-list">
                    <h3 className="item-title">Shared Pool</h3>
                    <p className="item-number">
                      {formatNumberMega(setting?.config)}
                      <span>/50,000,000</span>
                    </p>
                    {userProfile?.wallet && (
                      <PriceUsd style={{ marginBottom: "10px" }}>
                        ${formatNumberMega(usdPrice * setting?.config)}
                      </PriceUsd>
                    )}
                    <button className="button-contract">
                      Contract Pool
                      <img
                        src="img/Dashboard/mining/icn_external.png"
                        width={14}
                        height={14}
                        alt="external"
                      />
                    </button>
                    <img
                      className="image-oni"
                      src="/img/Dashboard/Pool/icn_01.png"
                      width={104}
                      alt=""
                    />
                  </div>
                </div>
                <PoolDetail>
                  <h3 className="title-detail">Pool Profit Detail</h3>
                  <ul className="list-pool-detail">
                    {listDetail.map((item: any, index: number) => (
                      <li key={index}>
                        <p>{item.textLeft}</p>
                        <p>{item.textRight}</p>
                      </li>
                    ))}
                  </ul>
                </PoolDetail>
              </PoolList>
              <PoolReward>
                <img
                  src="/img/Dashboard/Pool/icn_reward.png"
                  alt="Total Reward"
                  width={58}
                  height={58}
                  className="image-left"
                />
                <div className="content-right">
                  <h3 className="title-reward">Total Reward</h3>
                  <p className="number-reward">
                    {formatNumberMega(oniBalance ? oniBalance : "--")}{" "}
                    <img
                      src="/img/Dashboard/Pool/icn_token.png"
                      width={50}
                      height={50}
                      alt="Oni"
                    />
                  </p>
                  {userProfile?.wallet && (
                    <PriceUsd style={{ marginBottom: "5px" }}>
                      ${formatNumberMega(usdPrice * oniBalance)}
                    </PriceUsd>
                  )}
                  <p className="text-reward">
                    Profit Pool Distribute at 29th, Sep, 2024
                  </p>
                </div>
              </PoolReward>
            </>
          )}
        </PoolWrapper>
      </PoolContainer>
    </>
  );
};

const listDetail = [
  {
    textLeft: "Withdraw Fee",
    textRight: "15%",
  },
  {
    textLeft: "Buy Tax",
    textRight: "0.8%",
  },
  {
    textLeft: "Sell Tax",
    textRight: "0.9%",
  },
  {
    textLeft: "ONI Exchange Profit",
    textRight: "0.5%",
  },
  {
    textLeft: "ONI Bot Fee",
    textRight: "0.5%",
  },
];

export default Pool;
