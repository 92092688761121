import styled from "styled-components";

export const HeaderBody = styled.div`
  background: #1a1a1a;
  padding: 15px 15px 5px;
  @media screen and (max-width: 767.98px) {
    padding: 10px 15px;
  }
`;
export const HeaderLogo = styled.div`
  display: block;
`;
export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const HeaderNavigation = styled.ul`
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: #0c0e0e;
  padding: 4px;
  gap: 5px;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    border: 1px solid transparent;
    border-radius: 6px;
    transition: all 0.15s linear;
    &.active,
    &:hover {
      border-color: rgba(255, 255, 255, 0.4);
      background: rgba(40, 40, 40, 0.7);
      background-blend-mode: luminosity;
      box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
      backdrop-filter: blur(50px);
    }
  }
  @media (min-width: 1025px) and (max-width: 1440px) {
    a  {
      padding: 10px 10px;
    }
  }
  @media screen and (max-width: 1024px) {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 5px;
    z-index: 900;
    border-radius: 0;
    justify-content: space-between;
    overflow: auto hidden;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 200px;
      height: 200px;
      background: linear-gradient(-50deg, rgba(10, 255, 120, 0.00) 0%, #042312 50%);
      filter: blur(50px);
      pointer-events: none;
      z-index: -1;
    }
    a {
      opacity: .5;
      padding: 10px;
      font-size: 13px;
      font-weight: 400;
      border: 0;
      &.animation-more {
        animation: flowIconMore .3s linear;
      }
      &.animation-less {
        animation: flowIconLess .3s linear;
      }
      &.active {
        opacity: 1;
        border: none;
        background: none;
        box-shadow: none;
        backdrop-filter: unset;
        background-blend-mode: unset;
      }
      &:hover {
        border: none;
      }
      img {
        margin-bottom: 4px;
        width: 29px;
        height: 29px;
      }
    }
  }
  @keyframes flowIconMore {
    0% {
      transform: translateX(500px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes flowIconLess {
    0% {
      transform: translateX(500px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
`;
export const HeaderActions = styled.div`
  display: flex;
  gap: 6px;
  border-radius: 8px;
  background: #050505;
  @media screen and (max-width: 767px) {
    padding: 4px;
  }
`;
