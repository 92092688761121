import { useContext } from "react";
import {
  NetworkStaking,
  StakingList,
  StakingListBody,
  StakingListHeader,
} from "../styled";
import { ContextProviderWrapper } from "../../../components/Context";
import moment from "moment";
import { shortenAddress } from "../../../utils/addressUser";
import { formatNumberMega } from "../../../utils/formatNumber";
import TableCommon from "../../../components/Common/Table";
import { Modal } from "antd";
import "./modal.css";

const NetworkMember = ({
  filteredTeam,
  headingDataDesktop,
  isModalOpen,
  handleCancel,
}: any) => {
  const { netWorkMember, setNetWorkMember } = useContext(
    ContextProviderWrapper
  )!;
  const handleCloseMember = () => {
    setNetWorkMember(false);
  };

  return (
    <>
      <Modal
        className="modal-member"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
      >
        <NetworkStaking className={netWorkMember && "is-show"}>
          {/* <div className="close-member" onClick={handleCloseMember}>
            <img src="/img/Common/close_icon.svg" alt="Close" />
          </div> */}
          <StakingList>
            <StakingListHeader>
              <div>
                <h2>Team {filteredTeam?.team || "--"}</h2>
                <p>
                  Period: <span>24 Months</span>
                </p>
              </div>
              <div>
                <p>{filteredTeam?.totalMember || "0"}</p>
                <figure>
                  <img
                    width={25}
                    height={24}
                    src="./img/Common/ppl_icon.png"
                    alt="icon"
                    loading="lazy"
                  />
                </figure>
              </div>
            </StakingListHeader>
            {filteredTeam?.memberF1?.length === 0 ? (
              <p
                className="empty-data"
                style={{
                  textAlign: "center",
                }}
              >
                Doesn't have any data
              </p>
            ) : (
              <>
                <StakingListBody>
                  <TableCommon
                    data={filteredTeam?.memberF1}
                    heading={headingDataDesktop}
                  />
                </StakingListBody>
              </>
            )}
          </StakingList>
        </NetworkStaking>
      </Modal>
    </>
  );
};

export default NetworkMember;
